import { api, user } from '@/api'
import { cookie } from '@/utils'
import md5 from 'md5';
export default {
    name: 'Login',

    created () {
        // 判断是否有登录
        if (_global.token && this.$route.name == 'Login') return this.$router.push({name: '/Ceping'})
    },

    methods: {
        // 用户登录接口
        handleSubmit() {
            if (this.isAjax) return
            this.isAjax = true
            user.login({
                mobile: this.mobile,
                code: this.code
            }).then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message);
                this.$toast('登录成功');
                _global.isLogin = true
                _global.userInfo = res.data.userinfo
                _global.uid = res.data.userinfo.uid
                _global.token = res.data.token
                cookie.setCookie('token', res.data.token)
                cookie.setCookie('uid', res.data.userinfo.uid)
                setTimeout(() => {
                    let url = this.$route.query.url
                    if (url) return window.location.href = url
                    this.$router.replace({name: 'Me'})
                }, 500)
            }).catch(err => {
                this.$toast(JSON.stringify(err));
                this.isAjax = false
                console.log(err)
            })
        },

        // 手机绑定接口
        handleSubmitBind() {
            if (this.isAjax) return
            this.isAjax = true
            user.bind({
                mobile: this.mobile,
                code: this.code
            }).then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message);
                this.$toast('手机号码绑定成功');
                _global.userInfo.mobile = this.mobile
                setTimeout(() => {
                    let url = this.$route.query.url
                    if (url) return window.location.href = url
                    this.$router.replace({ name: 'Me' })
                }, 500)
            }).catch(err => {
                this.$toast(JSON.stringify(err));
                this.isAjax = false
                console.log(err)
            })
        },

        // 发送验证码
        handleSendCode () {
            if (this.isAjax || this.timer != null) return
            this.isAjax = true
            let options = {
                mobile: this.mobile,
                sign: md5(this.key + this.mobile)
            };
            api.sendCode(options).then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message);
                this.$toast('验证码发送成功');
                this.handleSetTime()
            }).catch(err => {
                this.$toast('验证码发送失败');
                this.isAjax = false
                console.log(err)
            })
        },

        // 倒计时
        handleSetTime () {
            if (this.time <= 0) {
                this.codeText = '获取验证码'
                this.timer = null
                this.time = 60
                clearTimeout(this.timer)
                return;
            }
            this.timer = setTimeout(() => {
                this.time--
                this.codeText = this.time + 's'
                this.handleSetTime()
            }, 1000)
        }
    },

    data() {
        return {
            isAjax: false,
            mobile: '',
            code: '',
            key: '_test_',

            codeText: '获取验证码',
            timer: null,
            time: 60
           
        }
    }

}